import React from "react";
import { StaticImage } from "gatsby-plugin-image";

//css
import * as styles from "./AboutUss.module.css";

const OurInvestors = () => {
  return (
    <div className="bgWhiteColor">
      <div className="container pb-3">
        {/* heading */}
        <div className={`row ${styles.globalTeam__row1}`}>
          <div className={`col-12 ${styles.globalTeam__lineCol}`}></div>
          <div className={`col-12 ${styles.globalTeam__hdngCol}`}>
            <h3 className="text-center">Our Investors</h3>
          </div>
        </div>
        <div className={`row ${styles.globalTeam__row2}`}>
          <div className={`col-6 col-md-3 col-lg-3 ${styles.investor__img1}`}>
            <StaticImage
              src="../../images/aboutUs/sequoia.jpg"
              alt="Founder"
              placeholder="blurred"
              width={125}
            />
          </div>
          <div className={`col-6 col-md-3 col-lg-3 ${styles.investor__img2}`}>
            <StaticImage
              src="../../images/aboutUs/truarrow.png"
              alt="Founder"
              placeholder="blurred"
              width={125}
            />
          </div>
          <div className={`col-6 col-md-3 col-lg-3 ${styles.investor__img3}`}>
            <StaticImage
              src="../../images/aboutUs/iconiq.png"
              alt="Founder"
              placeholder="blurred"
              width={125}
            />
          </div>
          <div className={`col-6 col-md-3 col-lg-3 ${styles.investor__img4}`}>
            <StaticImage
              src="../../images/aboutUs/sofina.png"
              alt="Founder"
              placeholder="blurred"
              width={125}
            />
          </div>
        </div>
        <div className={`row ${styles.globalTeam__row3}`}>
          <div className={`col-6 col-md-3 col-lg-3 ${styles.investor__img5}`}>
            <StaticImage
              src="../../images/aboutUs/corner_ventures.png"
              alt="Founder"
              placeholder="blurred"
              width={125}
            />
          </div>
          <div className={`col-6 col-md-3 col-lg-3 ${styles.investor__img6}`}>
            <StaticImage
              src="../../images/aboutUs/perceptive_advisors.png"
              alt="Founder"
              placeholder="blurred"
              width={125}
            />
          </div>
          <div className={`col-6 col-md-3 col-lg-3 ${styles.investor__img7}`}>
            <StaticImage
              src="../../images/aboutUs/helena.jpg"
              alt="Founder"
              placeholder="blurred"
              width={125}
            />
          </div>
          <div className={`col-6 col-md-3 col-lg-3 ${styles.investor__img8}`}>
            <StaticImage
              src="../../images/aboutUs/lts_ventures.jpg"
              alt="Founder"
              placeholder="blurred"
              width={125}
            />
          </div>
        </div>
        <div className={`row ${styles.globalTeam__row3}`}>
          <div className={`col-3 col-md-3 col-lg-3 ${styles.investor__img5}`}>
          </div>
          <div className={`col-6 col-md-6 col-lg-6 text-center mb-3`}>
            <StaticImage
              src="../../images/aboutUs/Temasek_Logo.jpg"
              alt="Founder"
              placeholder="blurred"
              width={125}
            />
          </div>
          <div className={`col-3 col-md-3 col-lg-3 ${styles.investor__img7}`}>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurInvestors;
