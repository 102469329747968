import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

//css
import * as styles from "./AboutUss.module.css";

const teamDetails = [
  {
    id: "jahangir-mohammed",
    drName: "Jahangir Mohammed",
    designation: "Founder/CEO",
    company: "Twinhealth",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/jahangir_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Twin Health was founded by Jahangir Mohammed, a serial inventor and entrepreneur, with a mission to reverse chronic diseases and help people live healthier and happier lives. Jahangir was the Founder/CEO of Jasper, which he helped build into a global market leader with Internet of Things (IoT) platforms. Currently used by over 10,000 enterprises around the world, Jasper was acquired by Cisco for $1.45B. Prior to Jasper, Jahangir was the Founder/CEO of Kineto Wireless, which pioneered the technologies to enable mobile phones to seamlessly use WiFi for voice and data. The technologies invented at Kineto became the basis for the UMA standard.",
    content2:
      "Jahangir is a prolific inventor with over 80 patents. He was named IoT Technology Pioneer at the World Economic Forum and was recognized for his visionary work and social impact by the Einstein Foundation. He was awarded an Honorary Doctorate by Concordia University, Canada.",
  },
  {
    id: "terry-poon",
    drName: "Terry Poon",
    designation: "Co-Founder/CTO",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/terry_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Terry Poon co-founded Twin Health and drives the vision for technology development. He leads the development of Twin's innovative platform and unique algorithms to improve human metabolic health using IoT, machine learning, and Digital Twin technologies. Prior to Twin, Terry was VP of Engineering at Jasper Technologies, where he built global software engineering teams and served as a lead architect of the company’s IoT cloud platform. In addition, Terry led engineering efforts for Jasper’s launch in the China market, the fastest-growing in the company’s history. Prior to Jasper, Terry held engineering and management positions at Oracle.",
    content2:
      "Terry graduated from MIT with a Master's in Computer Science Engineering and BS degrees in Computer Science and Economics. He holds 10 patents in software and analytics technologies.",
  },
  {
    id: "maluk-mohamed",
    drName: "Maluk Mohamed",
    designation: "Co-Founder/VP Research/Head (India)",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/maluk_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Maluk co-founded Twin Health and as VP of Research, he leads global trend-studies and guides the development of strategic initiatives based on research outcomes. He also runs operations for the Twin Service in India, including its launch in 2019. Prior to Twin, Maluk founded and served as President and Chairman of the MAM Group of Institutions in Tamil Nadu, India. Under his leadership, the institution grew to 10 colleges, currently educating more than 10,000 undergraduate and graduate students in engineering, architecture, business administration, and nursing.",
    content2:
      "Maluk earned his PhD from the Indian Institute of Technology Madras in the area of distributed mobile computing, and his Master of Engineering degree from National Institute of Technology Tiruchirappalli. He has published 178 research publications in various international journals and conference proceedings.",
  },
  {
    id: "lisa-shah-md",
    drName: "Lisa Shah, MD",
    designation: "Chief Medical Officer",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/lisa_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Lisa Shah, MD is responsible for US clinical operations, outcomes, and innovation to reverse diabetes and other chronic disease. She has years of hands-on experience in healthcare and innovation, from designing value-based care models to leading the implementation of new care delivery models in the practice setting. Prior to joining Twin, Lisa served as CMO for Advantia Health, Chief Innovation Officer for Sound Physicians, and SVP of Clinical Operations and Network for Evolent Health.",
    content2:
      "Lisa graduated from Duke University with a BA in English and completed medical school at the University of Medicine and Dentistry of New Jersey. At the University of Chicago, she completed her residency in Internal Medicine and Pediatrics, a post-residency health services research fellowship, and a Master's degree in Public Policy. Lisa is a member of Women of Impact in Healthcare and has received multiple awards from the Washington Business Journal, including the annual C-Suite award in 2019.",
  },
  {
    id: "shashank-joshi",
    drName: "Shashank R Joshi",
    designation: "Medical Scientist - Research",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/shashank_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Shashank Joshi, MD is Twin's chief scientist. As an endocrinologist and medical researcher, Dr. Joshi has extensive experience with the treatment and study of diabetes and chronic disease. Before joining Twin, he was the Chair of the South-East Asian International Diabetes Federation and president of several organizations: the Indian Academy of Diabetes, the Endocrine Society of India, the Association of Physicians of India, and the Research Society for Study of Diabetes in India. Dr. Joshi was a member of the faculty at Grant Medical College and Seth GS Medical College, where he earned his MBBS and MD, respectively. He is a fellow of the American College of Endocrinology, the American College of Physicians, and the Royal College of Physicians (London, Glasgow, and Edinburgh).",
    content2:
      "Dr. Joshi has published more than 800 research publications and Stanford University ranked him in the Top 0.5% of all scientists in General and Internal Medicine.",
  },
  {
    id: "john-davis",
    drName: "John Davis",
    designation: "Chief Commercial Officer",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/john_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "John is responsible for leading Twin Health’s US commercial strategy and growth. John has 20 years’ experience in healthcare with deep focus on value-based care transformation and risk-based contracting models. Prior to joining Twin, John was an early member of the Evolent Health growth team where he supported Evolent’s growth across Medicare, Medicaid, and commercial market segments resulting in Evolent’s IPO in 2015.",
    content2:
      "John graduated magna cum laude from the University of Georgia with a BBA in International Business.",
  },
  {
    id: "jen-bealer",
    drName: "Jen Bealer",
    designation: "Chief Legal Officer",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/jen_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Jennifer Bealer serves as Twin's Chief Legal Officer. Prior to that she was Executive Vice President, General Counsel and Secretary of Progyny Inc. Before Progyny Jennifer was an Associate at the law firm Ropes & Gray’s nationally-ranked healthcare practice from November 2010 to October 2017, where she gained extensive expertise in providing healthcare clients with strategic, regulatory, compliance and transaction advice.",
    content2:
      "Ms. Bealer holds a B.S. in Biology and Psychology from the Pennsylvania State University and received her J.D. from the University of Pennsylvania Law School, A.L.M from Harvard University, and Master of Bioethics from University of Pennsylvania School of Medicine.",
  },
];

const GlobalLeadershipTeam = () => {
  return (
    <div className="bgWhiteColor">
      <div className="container">
        {/* heading */}
        <div className={`row ${styles.globalTeam__row1}`}>
          <div className={`col-12 ${styles.globalTeam__hdngCol}`}>
            <h3 className="text-align-center">Global Leadership Team</h3>
          </div>
        </div>
        <div className={`row ${styles.globalTeam__row2}`}>
          {teamDetails.map((item, index) => (
            <div key={index} className="col-6 col-md-3 col-lg-3">
              <div className="row">
                <div className="col-12 col-md-12 pb-md-3 d-flex justify-content-center">
                  <div className={`${styles.customImg} `}>{item.drImage}</div>
                </div>
                <div className="col-12 col-md-12 pt-3">
                  <div className="col-12">
                    <div className={`${styles.drName} pb-md-1 text-center`}>
                      {item.drName}
                    </div>
                  </div>
                  <div className="col-12 pt-1  pb-1">
                    <div className={`${styles.design} pb-md-1 text-center`}>
                      {item.designation}
                    </div>
                  </div>
                  <div className="col-12 pb-3 pb-md-1">
                    <div className="text-center">
                      <Link to={"/about-us-details/?name=" + item.id}>
                        <span
                          role="presentation"
                          className={`${styles.bio} cursorPointer`}
                        >
                          Bio
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={`col-12 ${styles.globalTeam__spaceDiv}`}></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GlobalLeadershipTeam;
