import React from "react";

import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

//css
import * as styles from "./AboutUss.module.css";

const teamDetails = [
  {
    id: "jahangir-mohammed",
    drName: "Jahangir Mohammed",
    designation: "Founder/CEO",
    company: "Twinhealth",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/jahangir_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Twin Health was founded by Jahangir Mohammed, a serial inventor and entrepreneur, with a mission to reverse chronic diseases and help people live healthier and happier lives. Jahangir was the Founder/CEO of Jasper, which he helped build into a global market leader with Internet of Things (IoT) platforms. Currently used by over 10,000 enterprises around the world, Jasper was acquired by Cisco for $1.45B. Prior to Jasper, Jahangir was the Founder/CEO of Kineto Wireless, which pioneered the technologies to enable mobile phones to seamlessly use WiFi for voice and data. The technologies invented at Kineto became the basis for the UMA standard.",
    content2:
      "Jahangir is a prolific inventor with over 80 patents. He was named IoT Technology Pioneer at the World Economic Forum and was recognized for his visionary work and social impact by the Einstein Foundation. He was awarded an Honorary Doctorate by Concordia University, Canada.",
  },
  {
    id: "toby-cosgrove-md",
    drName: "Toby Cosgrove, MD",
    designation: "Former CEO",
    company: "Cleveland Clinic",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/toby_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Toby Cosgrove, MD is the former President and Chief Executive Officer of Cleveland Clinic and currently serves as an Executive Advisor for the health system.",
    content2:
      "Dr. Cosgrove is a sought-after speaker worldwide. He has addressed the World Economic Forum Annual Meeting at Davos, Switzerland, and the Senate Health, Education, Labor and Pensions Committee, in Washington, D.C. He is regularly quoted and featured in national magazines and newspapers, including a cover story in Time, and major articles in Newsweek, the New York Times, and the Washington Post. He has appeared on CNN, Fox, MSNBC, NBC, CBS, “The Charlie Rose Show” on PBS, and other national media outlets.",
    content3:
      "The recipient of Cleveland Clinic’s Master Clinician Award, Innovator of the Year Award, and Lerner Humanitarian Award, Dr. Cosgrove is also a member of Cleveland Medical Hall of Fame and Cleveland Business Hall of Fame. In 2007 he was named Cleveland Business Executive of the Year by the Sales and Marketing Executives of Cleveland, and Castle Connolly’s National Physician of the Year. He also received the Woodrow Wilson Center Award for Public Service as well as Harvard Business School’s Award from HBS Alumni, Cleveland, and the Humanitarian Award of the Diversity Center of Northeast Ohio. Dr. Cosgrove topped Inside Business’s “Power 100” listing for Northeast Ohio, and is highly ranked among Modern Healthcare’s “100 most powerful people in healthcare” and “most powerful physician executives.”",
  },
  {
    id: "mohit-bhatnagar",
    drName: "Mohit Bhatnagar",
    designation: "Managing Director",
    company: "Sequoia India",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/mohit_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "As a Managing Director with Sequoia India, Mohit focuses on high growth companies in the Payments, Consumer Internet, and SaaS sectors. He has served on the boards of Prizm Payments, Citrus Payments, Ujjivan Microfinance, Freshworks, Zomato, Circles.life, Near, DailyHunt, and One Assist, to name a few.",
    content2:
      "Prior to joining Sequoia, he was Senior Vice President at Bharti Airtel. An entrepreneur at heart, Mohit co-founded Bright pod, a wireless start-up in the US, in the year 2000, by orchestrating a spin off from Ericsson Mobile Systems. Mohit has an MBA from UNC Chapel Hill and an MSEE from Virginia Tech.",
  },
  {
    id: "caroline-xie",
    drName: "Caroline Xie",
    designation: "General Partner",
    company: "ICONIQ",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/xie_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Caroline Xie is a General Partner at ICONIQ investing in software, healthcare, and internet companies. Prior to ICONIQ, Caroline covered financial institutions and healthcare for Morgan Stanley in Hong Kong and California.",
    content2:
      "Caroline’s investments include Articulate, Benchling, Braze, Datadog (DDOG), GitLab, GoodRx (GDRX), Guild Education, Komodo Health, Moveworks, Nayya, QGenda, Reify Health, Relativity, Restaurant365, Sprinklr (CXM), Twin Health, Unite Us, and others.",
  },
  {
    id: "bruce-dunlevie",
    drName: "Bruce Dunlevie",
    designation: "Founder/General Partner",
    company: "Benchmark Capital",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/bruce_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Bruce Dunlevie has worked in the early stage technology venture capital industry for more than 30 years, and is a founder and general partner of Benchmark Capital, based in Silicon Valley. Benchmark was an early investor in numerous successful startups including eBay, Twitter, Uber, Snapchat, WeWork and Instagram. Prior to co-founding Benchmark Capital, Mr. Dunlevie worked in investment banking at Goldman, Sachs & Co.",
    content2:
      "Mr. Dunlevie serves on the board of the Stanford Management Company, the entity which oversees the Stanford University Endowment, and he is a trustee of The Getty Trust in Los Angeles. He is a former trustee of Stanford University and of his alma mater, Rice University. He is also a Fellow Benefactor of Trinity College, University of Cambridge.",
    content3:
      "Mr. Dunlevie earned a BA in literature and history from Rice University and an MBA from Stanford University, where he was an Arjay Miller Scholar.",
  },
  {
    id: "kevin-johnson",
    drName: "Kevin Johnson",
    designation: "Former CEO/President",
    company: "Starbucks",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/kevin_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Kevin Johnson most recently served as president and chief executive officer of Starbucks. Kevin first joined Starbucks in 2009, serving on the company’s board of directors during a period of record-setting transformation and growth. In March 2015, Kevin took on the role of president and chief operating officer, leading the company’s global operating businesses across all geographies, as well as the core support functions of Starbucks supply chain, marketing, human resources, technology and mobile and digital platforms. He assumed the role of president and CEO in April 2017, a role he held for five years prior to retiring in April 2022. Under Kevin’s leadership, Starbucks created significant value for all stakeholders. Kevin was the architect of Starbucks Growth at Scale agenda, transforming the way Starbucks drove innovation both in stores and digitally, expanding its global reach, significantly improving the company’s performance, and delivering record results.",
    content2:
      "His career spans more than 40 years, scaling global businesses, including 16 years at Microsoft, five years as CEO of Juniper Networks, 13 years at Starbucks including five years as president and CEO. He has also served our country when in 2008, he was appointed to the National Security Telecommunication Advisory Committee where he served both presidents George W. Bush and Barack Obama.",
    content3:
      "Kevin’s commitment to innovation and following his heart extends beyond the world of business. He was a founding board member of NPower, an organization that provides nonprofits with access to technology and the skills needed to fulfill their social missions. He is involved with Catalyst, a leading nonprofit focused on expanding opportunities for women in business. Kevin also supports Youth Eastside Services, one of the largest providers of youth and family counseling in the Seattle area. At Starbucks, Kevin is passionate about continuing to nurture what’s at the heart of the company’s success: human connection – the tens of millions of interactions large and small that happen between partners and customers every week, and make a meaningful difference in the communities around the world where Starbucks does business. He graduated from New Mexico State University with a bachelor’s degree in Business Administration.",
  },
];

const ExecutiveTeam = props => {
  return (
    <div className="bgWhiteColor">
      <div className="container">
        <div className={`row ${styles.globalTeam__row1}`}>
          <div className={`col-12 ${styles.globalTeam__lineCol}`}></div>
          <div className={`col-12 ${styles.globalTeam__hdngCol}`}>
            <h3 className="text-align-center">Board of Directors</h3>
          </div>
        </div>
        <div className={`row ${styles.globalTeam__row2}`}>
          {teamDetails.map((item, index) => (
            <div key={index} className="col-6 col-md-3 col-lg-3">
              <div className="row">
                <div className="col-12 col-md-12 pb-md-3 d-flex justify-content-center">
                  <div className={`${styles.customImg} `}>{item.drImage}</div>
                </div>
                <div className="col-12 col-md-12 pt-3">
                  <div className="col-12">
                    <div className={`${styles.drName} pb-md-1 text-center`}>
                      {item.drName}
                    </div>
                  </div>
                  <div className="col-12 pt-1  pb-1">
                    <div className={`${styles.design} pb-md-1 text-center`}>
                      {item.designation}
                    </div>
                  </div>
                  <div className="col-12 pt-1  pb-1">
                    <div className={`${styles.design} pb-md-1 text-center`}>
                      {item.company}
                    </div>
                  </div>
                  <div className="col-12 pb-3 pb-md-1">
                    <div className="text-center">
                      <Link to={"/about-us-details/?name=" + item.id}>
                        <span
                          role="presentation"
                          className={`${styles.bio} cursorPointer`}
                        >
                          Bio
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={`col-12 ${styles.globalTeam__spaceDiv}`}></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExecutiveTeam;
