import React from "react";

import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

//css
import * as styles from "./AboutUss.module.css";

const teamDetails = [
  {
    id: "francine-r-kaufman-md",
    drName: "Francine R. Kaufman, MD",
    designation: "Chief Medical Advisor",
    company: "Senseonics",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/francine_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Francine R. Kaufman, MD is the Chief Medical Officer of Senseonics, Inc. (2019-present). Previously, she was the CMO and VP of Global Medical Affairs at Medtronic Diabetes (2009-2019). She is a Distinguished Professor Emerita of Pediatrics at the Keck School of Medicine of the University of Southern California. After joining the Division of Endocrinology at Children’s Hospital Los Angeles in 1980, she served as Director of Diabetes (1990-2001) and then Division Head of Endocrinology (2001-2009). She has authored over 290 peer-reviewed and invited articles, and numerous books including Diabesity (Bantam, 2005), The Medical Management of Type 1 Diabetes (ADA, 2008), The Insulin Pump and Continuous Glucose Monitoring Book: For Patients, Parents and Caregivers (ADA, 2012, 2017) and Rhythms (a novel) (CKI Publisher, 2015).  ",
    content2:
      "She has received numerous grants, including being funded by the NIH for over 3 decades. She was elected to the National Academy of Medicine in 2005. Dr. Kaufman was national president of the ADA (2002-2003). She directed diabetes camps in Southern California, Ecuador, and Haiti for over 30 years. She was instrumental in banning sodas from LA Unified School District in 2004.",
  },
  {
    id: "osama-hamdy-md",
    drName: "Osama Hamdy, MD, PhD",
    designation: "Director Inpatient Diabetes Program",
    company: "Joslin Diabetes Center",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/osama_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Osama Hamdy, MD, PhD is a senior endocrinologist and Medical Director of the Joslin Obesity Clinical Program, and Director of the Inpatient Diabetes Program at Joslin Diabetes Center. He is also an Associate Professor of Medicine at Harvard Medical School. He is a world recognized expert and thought leader with 34 years of extensive clinical experience in nutrition and obesity research.",
    content2:
      "His lab led to innovations in how we manage obesity in patients with type 1 or type 2 diabetes. Dr. Hamdy’s research discovered that 7% weight loss in obese patients with and without diabetes significantly improves vascular endothelial function, insulin sensitivity and markers of inflammation. Dr. Hamdy was a co-investigator in landmark studies: the “Diabetes Prevention Program” and “the Look AHEAD Study.” Dr. Hamdy founded the “Weight Achievement and Intensive Treatment” (Why WAIT) program, helping patients with diabetes to be less dependent on diabetes medications. Dr. Hamdy co-chaired the task force that developed the global transcultural Diabetes Nutrition Algorithm (tDNA). His research led to changes in the standards of diabetes care and lifestyle management by the American Diabetes Association and the American Association of Clinical Endocrinologists.",
    content3:
      "Dr. Hamdy received the Michaela Modan Award of the American Diabetes Association and the Compassionate Caregiver Award of the Kenneth Schwartz Center. He has more than 150 peer-reviewed original articles, reviews and book chapters. He is also the author of the Harvard Health Publication “The Diabetes Breakthrough” reversing diabetes through weight management. He is on the editorial review board of many medical journals including Lancet, JAMA, British Medical Journal, Diabetes Care and Current Diabetes Report.",
  },
  {
    id: "ambrish-mithal-md",
    drName: "Ambrish Mithal, MD",
    designation: "Chairman and Head of Endocrinology & Diabetes",
    company: "Max Healthcare",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/ambrish_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Ambrish Mithal, MD is an endocrinologist and the Chairman and Head of Endocrinology and Diabetes at Max Healthcare, Saket, New Delhi; he started working in December 2019. One of the most renowned names in the field of Endocrinology, Dr. Ambrish Mithal is considered to be a thought leader in the medical community. Before joining Max Healthcare, he was Chairman, Division of Endocrinology and Diabetes, at Medanta the Medicity. There, he led the Endocrinology and Diabetes division from 2009 to 2019. Before that, he worked at Apollo Hospital, New Delhi, and Sanjay Gandhi Post Graduate Institute of Medical Sciences, Lucknow.",
    content2:
      "More recently, in 2019, Dr. Mithal has been nominated to the Governing Council of the National Health Authority, the apex body involved in implementing the ambitious population health coverage plan of the government. He has also been appointed (honorary) President, All India Institute of Medical Sciences, Gorakhpur.",
    content3:
      "Dr. Mithal was awarded the International Osteoporosis Foundation President's Award in March 2016. In March 2017, Dr. Mithal was awarded the Dr. B C Roy Award by the President of India for his contribution to the development of endocrinology in India. He has won numerous awards from medical and social bodies throughout his career.",
  },
  {
    id: "elbert-huang-md",
    drName: "Elbert Huang, MD",
    designation: "Director of the Center",
    company: "Chronic Disease Research",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/elbert_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Elbert Huang, MD is Professor of Medicine and Public Health Sciences, Director of the Center for Chronic Disease Research and Policy, and Associate Director of the Chicago Center for Diabetes Translation Research at the University of Chicago. From 2010-2011, he served as a Senior Advisor in the Office of the Assistant Secretary for Planning and Evaluation, Department of Health and Human Services.",
    content2:
      "Dr. Huang is a general internist who studies clinical and health care policy issues at the intersection of diabetes, aging, and health economics. His main research focus is in the area of medical decision making for elderly patients with type 2 diabetes where uncertainty exists regarding how to best individualize diabetes treatments based on clinical parameters and patient preferences. Over the past two decades, Dr. Huang has established one of the most active research programs in geriatric diabetes in the country.",
    content3:
      "Dr. Huang’s research has been supported by the National Institutes of Health, the Centers for Disease Control and Prevention, the American Diabetes Association, and the Juvenile Diabetes Research Foundation. He has received numerous honors including the Research Paper of the Year Award from the Society of General Internal Medicine and elected membership to the American Society for Clinical Investigation. Dr. Huang received his AB, MD, and MPH from Harvard University and came to the University of Chicago in 2001.",
  },
  {
    id: "rohit-satoskar-md",
    drName: "Rohit Satoskar, MD",
    designation: "Director of Medical Services",
    company: "MedStar Georgetown Transplant Institute",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/rohit_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Rohit Satoskar, MD, is the Director of Medical Services for the MedStar Georgetown Transplant Institute. He is also medical director of Liver Transplantation and Associate professor in the Department of Medicine at the MedStar Georgetown Transplant Institute. An expert in hepatology and liver transplantation, Dr. Satoskar is board-certified in Internal Medicine, Gastroenterology and Transplant Hepatology. He is widely published and a frequent lecturer, regionally and nationally. In addition to Dr. Satoskar's role as a clinician, teacher and researcher, he is actively involved in public policy efforts to benefit patients with liver disease. ",
    content2:
      "He serves in leadership positions with professional societies such as the American Association for the Study of Liver Diseases and the American Liver Foundation and is a founding board member of the Global Liver Institute dedicated to collaboration and innovation in dealing with liver disease. Dr. Satoskar is highly rated by patients for explaining things well, listening carefully, and treating patients with respect, among other things. He believes in personalized care for each patient to address their particular needs and help achieve the best possible outcome and quality of life. Each patient is cared for as an individual, using the latest evidence-based medicine and technology available.",
  },
  {
    id: "jeffrey-mechanick-md",
    drName: "Jeffrey Mechanick, MD",
    designation: "Director of Metabolic Support",
    company: "Icahn School of Medicine at Mount Sinai",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/jeffrey_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Mechanick is a Professor of Medicine and Medical Director of The Marie-Josée and Henry R. Kravis Center for Cardiovascular Health at Mount Sinai Heart, and Director of Metabolic Support in the Division of Endocrinology, Diabetes and Bone Disease, Icahn School of Medicine at Mount Sinai. He received his M.D. degree from the Icahn School of Medicine at Mount Sinai, completed Internal Medicine residency at Baylor College of Medicine, and completed Endocrine Fellowship at The Mount Sinai Hospital. Dr. Mechanick authored over 386 publications in Endocrinology, Metabolism, and Nutrition Support with 272 PubMed citations currently.",
    content2:
      "He is the 2016-2017 Past President of the American College of Endocrinology, 2013-2014 Past President of the American Association of Clinical Endocrinologists, and 2005-2006 Past President of the American Board of Physician Nutrition Specialists. Dr. Mechanick co-edited Nutritional Strategies for the Diabetic and Prediabetic Patient; Power of Prevention: The Complete Guide to Lifelong Nutrition; Thyroid Cancer: From Emergent Biotechnology to Clinical Practice Guidelines; Molecular Nutrition – The Practical Guide, Lifestyle Medicine – A Manual for Clinical Practice, and Creating a Lifestyle Medicine Center; and co-authored Diabetes Mellitus Pocket and Nutritional Medicine Pocket.",
    content3:
      "Dr. Mechanick was appointed as a member of the President’s Council on Fitness, Sports and Nutrition (PCFSN) Science Board for 2010-2013 and was the 2013-2015 Editor-in-Chief and is currently Editor-in-Chief Emeritus of the PCFSN quarterly publication Elevate Health. Dr. Mechanick chairs the Physician Engagement Committee of the American Association for Parenteral and Enteral Nutrition. Dr. Mechanick also chairs the International Transcultural Diabetes Nutrition Algorithm Working Group. Dr. Mechanick currently serves as Chair of the Board of Visitors for the College of Computer, Mathematics, and Natural Sciences at the University of Maryland at College Park and was the 2011 recipient of the University of Maryland Industry Impact Award and 2011 University of Maryland Biology Alumnus Award. Dr. Mechanick’s research interests are in the fields of nutrition and metabolic support, obesity and lifestyle medicine, and network analysis of complex systems. Dr. Mechanick is responsible for training cardiology fellows at Mount Sinai Heart in preventive cardiology.",
  },
  {
    id: "anoop-misra",
    drName: "Anoop Misra",
    designation: "Chairman - CoE - Diabetes",
    company:
      "Metabolic & Endocrinology Fortis Healthcare",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/anoop_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr Misra is Chairman, Fortis-CDOC Center of Excellence for Diabetes, Metabolic Diseases, and Endocrinology, New Delhi, and Director, National Diabetes, Obesity and Cholesterol Foundation, and Diabetes Foundation (India). After doing undergraduate and postgraduation from All India Institute of Medical Sciences, New Delhi, he has worked in Royal Free Hospital in London, UK and at Department of Medicine and Endocrinology at Southwestern Medical Center at Dallas, Texas, USA as WHO Fellow and later, as a faculty. In India, Dr Misra has been participant of top Advisory Committees relating to Diabetes, Cardiovascular diseases, and Nutrition in India. He has been advisor to Ministry of Health, Indian Council of Medical Research and Department of Biotechnology on several issues related to diabetes and other non-communicable diseases.",
    content2:
      "Prof. Misra has more than 40 years of experience in teaching, service, research, and community health intervention programs and has published more than 350 scientific papers/chapters in the National and International journals (citations till Feb, 2023; ~ 39, 181, h-index 100 and i10 index: 410) and books. In 2021, an analysis of Stanford University, he is among top 2% of scientists in diabetes research from India to be ranked globally.",
    content3:
      "He is Editor-in-Chief of “Diabetes and Metabolic Syndrome: Clinical Research and Reviews” (Elsevier), and Clinical Keys (Elsevier), and Associate Editor of “Journal of Diabetes” (USA), and European Journal of Clinical Nutrition (UK). He is member, member Editorial Advisory Board of British Medical Journal, member, BMJ’s Regional Advisory Board for South Asia and member, Lancet Obesity Commission, 2023. Dr Misra has received numerous awards and 31 named orations. He has been awarded highest award for medicine in India Dr BC Roy award (2006) and India’s prestigious National Honor, Padma Shree (2007). He has received “Outstanding Investigator Award” from World India Diabetes Foundation in 2013. His current interest include research on nutrition, metabolism, and diabetes in relation to liver fat, pancreatic fat, lean skeletal muscle mass and vitamin D.He believes that everyone with diabetes should have access to quality care and that obesity should be treated as a chronic disease. He is committed to finding new ways to prevent and treat these conditions and to improving the lives of people affected by them.He is author of bestselling books “Diabetes with Delight” and “Diabetes ke Saath Khushaal Jeevan” (Bloomsbury).",
  },
  {
    id: "ashutosh-shukla",
    drName: "Dr. Ashutosh Shukla",
    designation: "Co-Founder & Medical Director",
    company:
      "Prana Integrative Medicine Institute",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/ashutosh_shukla.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Ashutosh Shukla is the Medical Advisor to Max Healthcare & Senior Director Internal Medicine with over 30 years of post M.D. (Medicine) work experience at top corporate hospitals of Delhi NCR. His portfolio at Max includes managing a variety of complex cases in the OPD, IPD and ICU. He leads a team of 150 doctors in developing and overseeing safety and quality standards for better patient outcomes. Dr. Shukla treated and managed more than 4000 patients with Covid-19 and continues to design specialised post Covid recovery plans for his patients.",
    content2:
      "Dr. Shukla is also the Co-Founder & Medical Director of Prana Integrative Medicine Institute. As a pioneer of India’s first holistic healthcare centre, he offers evidence based, personalized care integrating allopathic and alternative methods of treatment to address lifestyle related chronic diseases.",
  },
  {
    id: "banshi-saboo",
    drName: "Dr. Banshi Saboo",
    designation: "MBBS, MD, DNB, PhD President, RSSDI Chief Diabetologist & Chairman of Diabetes Care & Hormone Clinic",
    company:
      "Dia-Care,Ahmedabad",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/banshi@2x.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Banshi Saboo is a name that is synonymous with ‘Diabetologist’. For patients, Dr. Banshi Saboo truly defines why doctors in India are called: The men sent by god. With experience that’s deeper than an ocean and knowledge that’s an encyclopedia in itself, Dr. Banshi Saboo is truly a name to reckon with when it comes to diabetes care. Dr. Banshi Saboo firmly believes that diabetes is just an ailment, that any one of us can fight against if we maintain a proper lifestyle. It’s this positive attitude that he shows to the patients that give them a new lease on life.",
    content2:
      "Dr. Banshi Saboo is a chief Diabetologist and Chairman of the Diabetes Care & Hormone Clinic at Ahmedabad, He had also done a Ph.D. in Phenotyping & Genotyping of young Diabetics in India. He is known for his organizational skills, which were proved when he almost single-handedly carried out the 37th Annual Conference of RSSDI in Ahmedabad in 2009. Someone who knows the threat of diabetes when a person enters this phase, Dr. Banshi Saboo has worked at grass root levels to make sure that diabetes can be caught hold of at an early stage before patients make it part of their life.Dr. Banshi Saboo has conducted various free check-up camps and educational programs just to eradicate this epidemic from our lives. Dr. Banshi Saboo has been in various research projects i.e. Obesity in Children, Epidemiological study of Diabetes in Gujarat (ICMR INDIAN Study), Indian Heart Watch Study, etc. He has presented many scientific papers at many National and International Conferences. He also has many publications in peer-reviewed journals, scientific abstracts & book chapters in his credit.",
  },
  {
    id: "shashank-joshi-medical-board",
    drName: "Shashank R Joshi",
    designation: "Consultant Endocrine and Metabolic Physician",
    company: "",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/shashank_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Shashank Joshi is the President of two important organizations, the Indian Academy of Diabetes and the Association of Physicians of India (API). He was also a former President of the Research Society for Study of Diabetes in India (RSSDI) and the All India Association of Advancing Research in Obesity. He is the former Vice President of the Association of Physicians of India and is now its Advisory Board member. Joshi is involved with the activities of the American Association of Clinical Endocrinology (AACE) and is serving as the Chairperson of its India Chapter. Shashank Joshi is involved in evidence based research on Endocrinology with special emphasis on Diabetes, Obesity, Thyroid, Osteoporosis and Growth. His studies on the Asian Indian phenotype, co-morbidities in hypothyroidism and the impact of vitamin D deficiency on bone health in Asian Indians are notable. He has also worked as the principal investigator in many teams engaged in multinational clinical trials and has covered two epidemics of diabetes and hypertension in India and Adult Growth Hormone deficiency.",
    content2:
      "Shashank Joshi is a prolific writer of articles on Diabetology and Endocrinology and has published 18 books and monograms and over 600 research publications on topics such as Diabetes, Obesity, Thyroid disorders, Osteoporosis, and Growth. He is also the Editor of Practical Medicine, a popular undergraduate Indian text book. He is also the Editor Emeritus of the Journal of the Association of Physicians of India and has been the Editor of three prominent Indian journals namely Indian Journal of Obesity, Indian Journal of Endocrinology and Metabolism and Indian Journal of Clinical Pharmacology and Therapeutics. Dr. Joshi founded the Indian Journal of Critical Care Medicine and was its Executive Editor. Shashank R Joshi was honoured by the Government of India by awarding him the Padma Shri, in 2014, in recognition of his efforts to the cause of medicine. He is also a recipient of the International Clinician Award, received at the 21st American Association of Clinical Endocrinologists (AACE) Annual Scientific and Clinical Congress, Philadelphia. This is an annual award given to practicing endocrinologists, outside the US. Pharma Leaders in the year 2013 conferred Medical Expert of the Decade at 6th Annual Pharmaceutical Leadership Summit founded by Satya Brahma. Indian Affairs India Leadership Conclave 2014 edition declared Dr. Shashank Joshi as Healthcare Visionary of the Year.",
  },
  {
    id: "moushin-wali",
    drName: "Dr Moushin Wali",
    designation: "Senior Consultant",
    company:
      "SIR GANGA RAM HOSPITAL, New Delhi",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/mohan@2x.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Padmashri Professor Dr M Wali has served government of India for 35 years. During this illustrious career he has been a keen researcher astute teacher and a very competent Physician. He has discharged his services at  the Prestigious Ram Manohar Lohia Hospital and post graduate institute which is now Atal Bihari Vajpayee medical College, G B Pant Institute of Cardiothoracic and  vascular sciences and President Estate Clinic where he has served as a full time Physician to President R Venkataraman,Dr Shanker Dayal Sharma and Bharat Ratna Pranab Mukherjee which is very unique  and exemplary  record.Dr Wali has a mind boggling list of awards, distinctions and merit . he has published more than 120 book chapters and papers in reputed journals and textbooks of Medicine and  Cardiology.",
    content2:
      "His thesis on lipid metabolism was adjudged the best. The President of India has bestowed upon him Best Alumnus of Kanpur University.He has been decorated by Bharat Ratna President APJ Abdul Kalam with Padma Shri which is fourth civilian Honour of the country along with Indian Red cross medal for his personal attributes.He has been a member of CCRUM and supervised more than twenty theses in Unani medicine. He always promotes Indian system of Medicine and encourages Unani medicine and it's research.Dr Wali has been twice honoured with best physician by Union Health Minister and has been bestowed with Hakim Ajmal Khan Global award and lifetime achievement award in Unani medicne also. Above all Dr Wali is a very humble person ready to always help less privileged. HE HAS RECENTLY BEEN AWARDED DOCTOR OD SCIENCE FROM GERMANY.",
  },
  {
    id: "rajiv-kovil",
    drName: "Dr Rajiv kovil",
    designation: "MBBS General Physician, Diabetologist",
    company: "",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/rajiv_kovil@2x.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Rajiv Kovil is an experienced Endocrinologist in India with 22+ years in the field.",
    content2:
      "He is the Founding Member of United Diabetes Forum, Joint Secretary of National Society for Prevention of Heart Disease & Rehabilitation (NSPHERE), Member of National Editorial Board (2004) Heart Care Journal.He pursued his MBBS (Mumbai) from T N Medical College (Nair Hospital) in 1996, and then did his Post-Graduate Two Year Diploma In Diabetology (Mumbai University) from S L Raheja Hospital in 1999 where he was Gold Medallist in Diabetology.",
  },
  {
    id: "krishna-g-seshadri-advisor",
    drName: "Dr. Krishna G Seshadri",
    designation: "Senior Consultant Endocrinology and Diabetes at Apollo Hospitals",
    company: "",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/dr_krishna.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Krishna G Seshadri, an esteemed Endocrinologist with over 22 years of experience, practices at Apollo Hospitals Greams Road in Chennai. Dr. Seshadri, who holds an AB certification, specializes in a diverse array of endocrinology conditions. His expertise encompasses male hypogonadism, osteomalacia, glucocorticoid remediable aldosteronism, growth hormone deficiency, acute diarrhoea treatment, parathyroid diseases, pituitary diseases including pituitary dwarfism, Hba1c monitoring, ketosis-prone diabetes mellitus, pseudohypoparathyroidism, adrenal masses, alkaptonuria, menarche-related issues, Turner syndrome, familial hyperinsulinemic hypoglycemia, combined lipase deficiency, colloid goiter, and type 1 diabetes treatment. Dr. Seshadri offers comprehensive services to address these varied endocrinological concerns, ensuring high-quality care for his patients.",
    content2:
      "If you're seeking Endocrinologist in your neighbourhood then, Dr. Krishna G Seshadri is the right choice for expert care and treatment of your Endocrinology needs. Book an appointment today and experience top-notch medical care.",
  },
  {
    id: "manoj-chawla-advisor",
    drName: "Dr Manoj Chawla",
    designation: "Diabetologist, Executive Member, RSSDI",
    company: "",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/dr_manoj.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Manoj Chawla is a Diabetologist. He has 20 years of experience in this field.He did MBBS from University of Mumbai, Mumbai in 1997 and a Diploma in Diabetes from University of Mumbai, Mumbai in 1999.He is a member of the Maharashtra Medical Council, India representative at IDF General Assembly. Dubai 2011, Postgraduate lecturer for CPS-Diabetology courses conducted 6 months at CPS, Mumbai, and Chairperson at the 1st Medtronic CGMS and Insulin Pump Conference, Chennai. He is also the Director and Consultant Diabetologist at Lina Diabetes Care & Mumbai Diabetes Research Centre (Andheri-West) and the Founder and President of United Diabetes Forum.",
    content2:
      "He was recently awarded the `Best Consultant Diabetologist in Mumbai` Award, in appreciation of his excellence in the field of Diabetes. Dr. Chawla has co-authored and presented research papers at various national and international symposia. He has been the Principal Investigator for various global clinical trials for new antidiabetic drugs and his special interest areas are CGMS and Insulin Pumps.",
  },
  {
    id: "abhishek-katakwar-advisor",
    drName: "Dr Abhishek Katakwar",
    designation: "MBBS, MS - General Surgery, Fellow of American College of Surgeons(FACS) Bariatric Surgeon,General Surgeon, Laparoscopic Surgeon",
    company: "AIG Hospitals",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/dr_abhishek_katakwar.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Abhishek katakwar is a graduate (MBBS) and postgraduate (MS) from Mahatma Gandhi Institute of medical sciences, sewagram. He is trained in minimal access surgeries from Pius Hospital, Oldenburg, Germany. He was also trained in bariatric and metabolic surgery by world-renowned bariatric surgeon Dr C.K. Huang at Taiwan followed by robotic surgery training at Severance hospital, Seoul, South Korea. He has more than 15 years of training and experience in bariatric & metabolic surgery.He is a board certified member of the American Society of metabolic and Bariatric Surgery (ASMBS) and the Obesity and Metabolic Surgery Society of India (OSSI). He is also a fellow of the American College of Surgeons.",
    content2:
      "Dr. Abhishek katakwar has published many scientific articles and has made numerous presentations throughout the India and abroad. Despite his demanding schedule, Dr. Katakwar donates his time and talent to volunteer for “Health mantra”, an organization that provides free bariatric & metabolic surgery for underprivileged people suffering from obesity, diabetes and metabolic syndrome.",
  },
];

const MedicalAdvisorBoard = props => {
  return (
    <div className="bgWhiteColor">
      <div className="container">
        <div className={`row ${styles.globalTeam__row1}`}>
          <div className={`col-12 ${styles.globalTeam__lineCol}`}></div>
          <div className={`col-12 ${styles.globalTeam__hdngCol}`}>
            <h3 className="text-align-center">Medical Advisory Board</h3>
          </div>
        </div>
        <div className={`row ${styles.globalTeam__row2}`}>
          {teamDetails.map((item, index) => (
            <div key={index} className="col-6 col-md-3 col-lg-3">
              <div className="row">
                <div className="col-12 col-md-12 pb-md-3 d-flex justify-content-center">
                  <div className={`${styles.customImg} `}>{item.drImage}</div>
                </div>
                <div className="col-12 col-md-12 pt-3">
                  <div className="col-12">
                    <div className={`${styles.drName} pb-md-1 text-center`}>
                      {item.drName}
                    </div>
                  </div>
                  <div className="col-12 pt-1  pb-1">
                    <div className={`${styles.design} pb-md-1 text-center`}>
                      {item.designation}
                    </div>
                  </div>
                  <div className="col-12 pt-1  pb-1">
                    <div className={`${styles.design} pb-md-1 text-center`}>
                      {item.company}
                    </div>
                  </div>
                  <div className="col-12 pb-3 pb-md-1">
                    <div className="text-center">
                      <Link to={"/about-us-details/?name=" + item.id}>
                        <span
                          role="presentation"
                          className={`${styles.bio} cursorPointer`}
                        >
                          Bio
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={`col-12 ${styles.globalTeam__spaceDiv}`}></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MedicalAdvisorBoard;
