import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

//css
import * as styles from "./AboutUss.module.css";

const teamDetails = [
  {
    id: "ashok-jhunjhunwala",
    drName: "Dr Ashok Jhunjhunwala",
    designation: "Former Professor / IIT Madras & President, IITM Research Park",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/ashok@2x.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Prof. Ashok Jhunjhunwala’s first appointment was in Washington State University, USA from 1979 to 1980. Prof. Jhunjhunwala then joined IIT Madras in 1981 in the Department of Electrical Engineering. Over the last few decades he has looked at the cost and affordability of various components of the telecommunications and the Internet network, especially in the Indian context. He has worked on techniques to enable communication networks to reach remote parts of rural India. In recent times, he has made some significant contributions in Solar-DC and in battery systems for Electric Vehicles. He has guided more than 70 M.S. and Ph.D. students.",
    content2:
      "Over the course of his career Jhunjhunwala has served as Chairman and member of various government committees. He has been on boards of several private companies and educational institutions in the country. He was on the boards of the State Bank of India, Bharat Electronics Ltd, Hindustan Teleprinters Limited, NRDC, Institute for Development and Research in Banking Technology (IDRBT), VSNL and BSNL as well as Tata Communications, Mahindra Electric, Sasken, Tejas Networks, Tata Teleservices Maharashtra Ltd, Intellect and Exicom. As of 2020 he is on the board of Biotechnology Industry Research Assistance Council (BIRAC), Chairman of IIITH and is the Chairman of Technology Advisory Committee of SEBI In 2017 - 18, Jhujhunwala was on sabbatical from IITM, serving as the Principal Advisor to the Ministry of Power in the Government of India, and the Ministry of Railways in the Government of India, New Delhi.",
  },
  {
    id: "rajiv-kumar",
    drName: "Dr Rajiv Kumar",
    designation: "Indian economist who had served as the second vice-chairman of the NITI Aayog",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/rajiv@2x.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Rajiv Kumar, former Vice Chairman of NITI Aayog, recently resumed his chairmanship of Pahle India Foundation, a not-for-profit policy think tank, established by him in 2013. Dr. Kumar’s career has spanned academics to government to corporate chambers and multilateral institutions. The wide range of experience, combined with his intellectual depth, as reflected in his substantial publication record, gives him a unique multi-dimensional perspective on the Indian economy. He served in the government as Economic Advisor with the Department of Economic Affairs (Ministry of Finance) during 1991-1995 and took over as Vice Chairman of NITI Aayog in September 2017, with the rank of Cabinet Minister and served until April 2022.",
    content2:
      "His stints with the corporate sector include serving as Chief Economist of the Confederation of Indian Industries (CII) from 2004-2006 and later as Secretary General of the Federation of Indian Chambers of Commerce and Industry (FICCI), 2011-2013. Dr Kumar has a Ph.D. in Economics from Lucknow University (1978) and a D.Phil. from Oxford University (1982). He has been conferred with D.Litt. (honorary causa) by several universities.",
  },
  {
    id: "s-prakash",
    drName: "Dr S Prakash",
    designation: "MD Of Star health Insurance",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/prakash@2x.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr S Prakash, M.S., FRCS (Glasg), FAIS, a passionate Medical Doctor who became Managing Director of a Dominant Health Insurance Company in India- Star Health Insurance. He is the first of its kind to have 2 decades of clinical experience from India & Overseas and close to 15 years of experience in Health Insurance Industry. During the past 14 years of his tenure, he grew from Medical Director, Executive Director, Chief Operating Officer, to Joint Managing Director and now elevated as a Managing Director. His efficiency in contributing to the functional growth of Star Health Insurance is reflected in the GWP growth of USD 3 million in 2007 to USD 1.47 billion in FY22.",
    content2: "He is the Chairperson of CII Tamil Nadu Healthcare Panel for consecutive 3 years. Currently, the Convenor of FICCI Tamil Nadu Insurance Panel, Executive Council Member of General Council of General and Health Insurers. Under his leadership the company has received many awards, the noteworthy one being ‘The World Health Day Award of Excellence’ from the Honourable President of India, Shri Pranab Mukherjee.",
  },
];

const Advisors = () => {
  return (
    <div className="bgWhiteColor">
      <div className="container">
        {/* heading */}
        <div className={`row ${styles.globalTeam__row1}`}>
          <div className={`col-12 ${styles.globalTeam__lineCol}`}></div>
          <div className={`col-12 ${styles.globalTeam__hdngCol}`}>
            <h3 className="text-align-center">Advisors</h3>
          </div>
        </div>
        <div className={`row ${styles.globalTeam__row2}`}>
          {teamDetails.map((item, index) => (
            <div key={index} className="col-6 col-md-3 col-lg-3">
              <div className="row">
                <div className="col-12 col-md-12 pb-md-3 d-flex justify-content-center">
                  <div className={`${styles.customImg} `}>{item.drImage}</div>
                </div>
                <div className="col-12 col-md-12 pt-3">
                  <div className="col-12">
                    <div className={`${styles.drName} pb-md-1 text-center`}>
                      {item.drName}
                    </div>
                  </div>
                  <div className="col-12 pt-1  pb-1">
                    <div className={`${styles.design} pb-md-1 text-center`}>
                      {item.designation}
                    </div>
                  </div>
                  <div className="col-12 pb-3 pb-md-1">
                    <div className="text-center">
                      <Link to={"/about-us-details/?name=" + item.id}>
                        <span
                          role="presentation"
                          className={`${styles.bio} cursorPointer`}
                        >
                          Bio
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={`col-12 ${styles.globalTeam__spaceDiv}`}></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Advisors;
