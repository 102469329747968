import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

//css
import * as styles from "./AboutUss.module.css";

const teamDetails = [
  {
    id: "terry-poon",
    drName: "Terry Poon",
    designation: "Co-Founder/CTO",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/terry_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Terry Poon co-founded Twin Health and drives the vision for technology development. He leads the development of Twin's innovative platform and unique algorithms to improve human metabolic health using IoT, machine learning, and Digital Twin technologies. Prior to Twin, Terry was VP of Engineering at Jasper Technologies, where he built global software engineering teams and served as a lead architect of the company’s IoT cloud platform. In addition, Terry led engineering efforts for Jasper’s launch in the China market, the fastest-growing in the company’s history. Prior to Jasper, Terry held engineering and management positions at Oracle.",
    content2:
      "Terry graduated from MIT with a Master's in Computer Science Engineering and BS degrees in Computer Science and Economics. He holds 10 patents in software and analytics technologies.",
  },
  {
    id: "ankit-mishra",
    drName: "Ankit Mishra",
    designation: "Product Director",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/ankit_new.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Ankit holds an engineering degree from BITS Pilani and an MBA from IIM Bangalore, accumulating a decade of diverse professional experience. Throughout his career, he has made significant contributions to the flourishing startup ecosystem in India, engaging with both unicorns and early-stage ventures. His journey began at Ola, where he tackled complex challenges in the Ops-tech industry, such as launching new business models, customer acquisition, loyalty-building, and scaling operations.",
    content2:
      "Subsequently, he joined Swiggy's strategy team, spearheading impactful projects to enhance profitability. At FalconX, a crypto institutional brokerage startup, he led operations and product setup for Asian clients. While there, he seized the opportunity to co-found a direct-to-consumer healthy food brand focused on dried fruits and nuts, unfortunately disrupted by the Covid-19 pandemic. Currently, Ankit has been with Twin for nearly three years, leading the India Product roadmap for Twin Health, where their mission is to leverage AI for the reversal of chronic diseases.",
  },
  {
    id: "sharath-reddy",
    drName: "Sharath Reddy",
    designation: "Engineering Director",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/sharath.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Sharath joined Twin as Engineering Director in Feb 2021 , he leads the Engineering team at Twin India. Prior to joining Twin, Sharath led product development at Amazon , Target Retail , and Motorola Solutions.",
    content2:
      "Sharath graduated from JNTU Hyderabad  with a Master's in Computer Science Engineering",
  },
];
const TechnologyTeam = () => {
  return (
    <div className="bgWhiteColor">
      <div className="container">
        {/* heading */}
        <div className={`row ${styles.globalTeam__row1}`}>
          <div className={`col-12 ${styles.globalTeam__lineCol}`}></div>
          <div className={`col-12 ${styles.globalTeam__hdngCol}`}>
            <h3 className="text-align-center">Technology Leadership Team</h3>
          </div>
        </div>
        <div className={`row ${styles.globalTeam__row2}`}>
          {teamDetails.map((item, index) => (
            <div key={index} className="col-6 col-md-3 col-lg-3">
              <div className="row">
                <div className="col-12 col-md-12 pb-md-3 d-flex justify-content-center">
                  <div className={`${styles.customImg} `}>{item.drImage}</div>
                </div>
                <div className="col-12 col-md-12 pt-3">
                  <div className="col-12">
                    <div className={`${styles.drName} pb-md-1 text-center`}>
                      {item.drName}
                    </div>
                  </div>
                  <div className="col-12 pt-1  pb-1">
                    <div className={`${styles.design} pb-md-1 text-center`}>
                      {item.designation}
                    </div>
                  </div>
                  <div className="col-12 pb-3 pb-md-1">
                    <div className="text-center">
                      <Link to={"/about-us-details/?name=" + item.id}>
                        <span
                          role="presentation"
                          className={`${styles.bio} cursorPointer`}
                        >
                          Bio
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={`col-12 ${styles.globalTeam__spaceDiv}`}></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TechnologyTeam;
