import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

//css
import * as styles from "./AboutUss.module.css";

const teamDetails = [
  {
    id: "maluk-mohamed",
    drName: "Maluk Mohamed",
    designation: "Co-Founder/VP Research/Head (India)",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/maluk_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Maluk co-founded Twin Health and as VP of Research, he leads global trend-studies and guides the development of strategic initiatives based on research outcomes. He also runs operations for the Twin Service in India, including its launch in 2019. Prior to Twin, Maluk founded and served as President and Chairman of the MAM Group of Institutions in Tamil Nadu, India. Under his leadership, the institution grew to 10 colleges, currently educating more than 10,000 undergraduate and graduate students in engineering, architecture, business administration, and nursing.",
    content2:
      "Maluk earned his PhD from the Indian Institute of Technology Madras in the area of distributed mobile computing, and his Master of Engineering degree from National Institute of Technology Tiruchirappalli. He has published 178 research publications in various international journals and conference proceedings.",
  },
  {
    id: "shashank-joshi",
    drName: "Shashank R Joshi",
    designation: "Medical Scientist - Research",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/shashank_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Shashank Joshi, MD is Twin's chief scientist. As an endocrinologist and medical researcher, Dr. Joshi has extensive experience with the treatment and study of diabetes and chronic disease. Before joining Twin, he was the Chair of the South-East Asian International Diabetes Federation and president of several organizations: the Indian Academy of Diabetes, the Endocrine Society of India, the Association of Physicians of India, and the Research Society for Study of Diabetes in India. Dr. Joshi was a member of the faculty at Grant Medical College and Seth GS Medical College, where he earned his MBBS and MD, respectively. He is a fellow of the American College of Endocrinology, the American College of Physicians, and the Royal College of Physicians (London, Glasgow, and Edinburgh).",
    content2:
      "Dr. Joshi has published more than 800 research publications and Stanford University ranked him in the Top 0.5% of all scientists in General and Internal Medicine.",
  },
  {
    id: "paramesh-s",
    drName: "Paramesh S",
    designation: "Medical Director - Research (RCT)",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/parmesh_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Over 30 years of expertise in Diabetology, Run 100+ clinical trials, Broadly published in medical journals",
    content2: "",
  },
  {
    id: "senthil-kumaran",
    drName: "Senthil Kumaran",
    designation: "Head India Finance",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/senthil_v1.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Senthil as a Chartered Accountant & graduate in Commerce from Madras University, alumni from Frankfurt School of Finance and Management, Senthil has more than 24 years of overall broad experience in leading teams in functions such as Finance, Legal, Human Resources, Supply Chain and Project Management in multiple industries across organisations as Nike, Norma Group,FCI, and Praxair with stints in India and Europe.",
    content2:
      "Moved back to pursue his entrepreneurial passion as Co-Founder of online gifting of precious jewels in the 100 $ segment named Zomint ,Worked as CFO for Cuemath a leading after school math learning venture, heading Finance, Human Resources and inorganic acquisitions. Senthil was CFO for Fitness & Digital business division of Curefit & Curefoods, cloud kitchen aggregator for 3 years with strong experience in Funds raising,Investor Relations etc., and been part of a number of inorganic brand acquisitions. Recently as Chief Strategy Officer with Akshayakalpa, Dairy FMCG, helped in raising funds and acquiring new businesses.",
  },
  {
    id: "suresh-damodharan",
    drName: "Suresh Damodharan",
    designation: "Director (Clinical)",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/suresh.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr Damodaran is the consultant, Diabetes & Endocrinology, Sri Ramakrishna Hospital, Coimbatore. He is also an honorary consultant in Derriford Hospital, Plymouth. He has held important positions in various societies and committees most notable being Vice President, Endocrine Society of India, Executive Council member, Endocrine Thyroid Society, Coimbatore and many such positions. He has received many awards in this illustrious career including the “ The Donnell D. Etzweller International Scholar award”.",
    content2: "",
  },
  {
    id: "sana-khan",
    drName: "Sana Khan",
    designation: "Director-Growth",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/sana@2x.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Sana Khan is responsible for expanding the reach of Twin Health across key sectors in India to make personalised wellness widely accessible.She brings a unique skill set to the organisation; a blend of commerce, effective communication and a result-oriented approach.Sana has over 15 years of experience in Broadcast news in India. She actively helped set up national and international news channels like WION, Rajya Sabha television, and India Ahead.",
    content2:
      "With her strong understanding of news and keen interest in national and international politics she has worked as a prime-time anchor, special reporter and headed Editorial teams across big networks across India like TV Today and INX media.Apart from her time in the business of news, Covid pandemic and her father's long-covid thrusted Sana into uncharted territory. She spearheaded management of her father's hospital as he recovered, and subsequently decided to pursue her passion for healing patients through a Diploma in clinical nutrition.Sana graduated from the Delhi University with a degree in Commerce and went on to major in Media management from the School of Convergence in the national capital.",
  },
  {
    id: "ravi-sankar",
    drName: "Dr. Ravi Sankar",
    designation: "Director-Medical",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/dr_ravi.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Ravi Sankar is an accomplished Endocrinologist and leader in the field of Medicine, Diabetes & Endocrinology. Commencing his career with numerous distinctions, prizes and merit certificates during his medical schooling, he had the opportunity to pursue his postgraduate specialist training in GIM, Diabetes and endocrinology and worked in some of the best medical centres in the UK.",
    content2:
      "After relocating back to India, he has been working as a Senior Endocrinologist at Apollo Hospital, Jubilee Hills, Hyderabad.",
    content3:
      "He held prestigious posts such as the National Clinical Lead for APOLLO SUGAR. He is the founder and served as the President of the Association of Clinical Endocrinologists, India. Dr. Ravi Sankar is known for steering and publishing guidelines on Type 2 Diabetes and on Insulin, in the Indian context.",
  },
  // {
  //   id: "mahesh-nambiar",
  //   drName: "Mahesh Nambiar",
  //   designation: "Associate Director-Growth",
  //   drImage: (
  //     <StaticImage
  //       src="../../images/aboutUs/mahesh_v2.png"
  //       alt="Founder"
  //       placeholder="blurred"
  //     />
  //   ),
  //   content1:
  //     "Mahesh brings over 15 years of experience in the healthcare, pharma, and life sciences industry, with expertise in business development, marketing, project management, process optimization, scientific support, and research. Prior to Twin health, he has been a part of NMC Healthcare, Strand Life Sciences, HCG group of hospitals, and two start-ups. With a background in drug discovery research and publication under his belt at Piramal group, Mahesh combines industry knowledge with strong interpersonal skills to deliver exceptional results. He is a seasoned professional who excels in driving growth, optimizing operations, and leading teams to success.",
  //   content2: "",
  // },
  {
    id: "abhijeet-shahi",
    drName: "Abhijeet Shahi",
    designation: "Associate Director-Marketing",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/abhijeet_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Over 12 years of experience across B2B & B2C marketing, sales growth, customer success and product management roles in medtech & healthcare ecosystems. Strong background of helping early stage organisations scale towards impact & structure. Exposure to a range of responsibilities from marketing strategy including data analytics, content marketing, product management, & partnership initiatives. Experience in consultative selling with the ability to deliver against planned KPIs & revenue targets.",
    content2: "",
  },
  {
    id: "asan-abdul",
    drName: "Asan Abdul",
    designation: "Associate Director-Customer success",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/asan_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Asan Abdul Khadhar, Associate Director of Customer Success, joined Twin Health in March 2023 and is in charge of providing a remarkable and faultless member experience from onboarding until a member is discharged. Handling all member-related grievances and providing on-time service. Asan, a Chennai native and MBA graduate, spent more than 20 years in Abu Dhabi working as a cost controller for Etihad Airport Services, a division of Etihad Airways. Expert in business processes with strong skills in supply chain, material management, accounting, marketing, and cost control.",
    content2: "",
  },
  {
    id: "ayesha-tabbassum-r",
    drName: "Ayesha Tabbassum R",
    designation: "Associate Director-Coach Operations",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/ayesha_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "As an Associate Director of Operations at Twin Health India, I am passionate about empowering individuals to lead healthy and happy lives. With a Master of Philosophy in Nutrition, I bring a strong foundation in the science of nutrition and its impact on overall well-being. I am committed to delivering exceptional service, inspiring positive lifestyle changes, and fostering long-term client success.",
    content2: "",
  },
  {
    id: "praveena-suresh",
    drName: "Praveena Suresh",
    designation: "Associate Director-Learning & Development",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/praveena_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr Praveena has over 17 years of experience in the field of learning & development. In Twin Health, she has been responsible for the learning and development efforts and most recently has taken the responsibility of sales conversion too. As an individual she is a self-motivated, certificated diabetes educator and professional diabetes management trainer with a passion for educating health care professionals.",
    content2: "",
  },
  {
    id: "vaishnavi-reddy",
    drName: "Vaishnavi Reddy",
    designation: "Associate Director-Clinical Operations",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/vaishnavi_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Vaishnavi Reddy, a highly accomplished leader within our startup, brings a wealth of expertise working with the Market leaders and trend -setters Drawing from her extensive knowledge across diverse Healthcare Industries, Dr.Vaishnavi leads our Clinical operations team with a unique blend of vision and practicality. She is passionate about leveraging technology to revolutionize traditional business models and propel our company into the future. Through her strategic guidance, she inspires our team to challenge boundaries, experiment with new ideas, and push the envelope of innovation. She believes in empowering individuals, encouraging them to take ownership of their ideas, and nurturing their professional growth.",
    content2: "",
  },
  {
    id: "shubhda-bhanot",
    drName: "Shubhda Bhanot",
    designation: "Chief Diabetes Educator",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/shubhda_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Shubhda has 23 years of experience in the field of nutrition & diabetes education. At Twin she works as manager growth & trainer. She is also the chief diabetes educator at Max Health Care Delhi, NCR. In the past share has held similar positions in Medanta – The Medicity, Sitaram Bhartia Institute of Science & Research, Apollo clinic, Cradle Hospital & VLCC. She is the executive member of various associations like Delhi Association of Diabetes Educators & has three chapters published on the book “Insulin Therapy – Current Concepts”.",
    content2: "",
  },
];

const IndiaLeadershipTeam = () => {
  return (
    <div className="bgWhiteColor">
      <div className="container">
        {/* heading */}
        <div className={`row ${styles.globalTeam__row1}`}>
          <div className={`col-12 ${styles.globalTeam__lineCol}`}></div>
          <div className={`col-12 ${styles.globalTeam__hdngCol}`}>
            <h3 className="text-align-center">Indian Leadership Team</h3>
          </div>
        </div>
        <div className={`row ${styles.globalTeam__row2}`}>
          {teamDetails.map((item, index) => (
            <div key={index} className="col-6 col-md-3 col-lg-3">
              <div className="row">
                <div className="col-12 col-md-12 pb-md-3 d-flex justify-content-center">
                  <div className={`${styles.customImg} `}>{item.drImage}</div>
                </div>
                <div className="col-12 col-md-12 pt-3">
                  <div className="col-12">
                    <div className={`${styles.drName} pb-md-1 text-center`}>
                      {item.drName}
                    </div>
                  </div>
                  <div className="col-12 pt-1  pb-1">
                    <div className={`${styles.design} pb-md-1 text-center`}>
                      {item.designation}
                    </div>
                  </div>
                  <div className="col-12 pb-3 pb-md-1">
                    <div className="text-center">
                      <Link to={"/about-us-details/?name=" + item.id}>
                        <span
                          role="presentation"
                          className={`${styles.bio} cursorPointer`}
                        >
                          Bio
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={`col-12 ${styles.globalTeam__spaceDiv}`}></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IndiaLeadershipTeam;
